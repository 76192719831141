<template>
  <div class="enterprise-detail">
    <ori-header />

    <div class="container enterprise-wrap">
      <div class="w1200 top-panel">
        <div class="img">
          <img src="http://dummyimage.com/120x120/0F52BA" alt="" srcset="" />
        </div>
        <div class="right-content">
          <div class="title-share">
            <span>精密医用导管及其衍生的心血管介入医疗器械</span>
            <span>
              <i class="el-icon-share"></i>
            </span>
          </div>
          <div class="info-wrap">
            <div class="left">
              <div class="info-row">
                <span>行业：</span>
                <span>医疗器械</span>
              </div>
              <div class="info-row">
                <span>地区：</span>
                <span>广东省 深圳</span>
              </div>
              <div class="info-row">
                <span>项目标签：</span>
                <span class="tag">医疗精密导管</span>
                <span class="tag">高值介入医疗器械</span>
                <span class="tag">微创医疗器械</span>
                <span class="tag">氟塑料精密导管</span>
              </div>
            </div>
            <div class="center">
              <div class="info-row">
                <span>官网：</span>
                <span>www.4U-med.com</span>
              </div>
              <div class="info-row">
                <span>融资需求：</span>
                <span>不需要融资</span>
              </div>
              <div class="info-row">
                <span>融资主体：</span>
                <span>企业</span>
              </div>
            </div>
            <div class="right">
              <el-button type="primary" size="small">查看项目名片</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="w1200 bottom-panel">
        <div class="left">
          <div class="nav-list">
            <span class="active">项目简介</span>
            <span>项目优势</span>
            <span>项目进展</span>
            <span>企业介绍</span>
            <span>主要成员</span>
            <span>融资计划</span>
            <span>推荐创新成果</span>
          </div>

          <div class="desc-wrap">
            <div class="title">项目简介</div>
            <div class="desc">
              深圳奉天医疗技术有限公司是中国首家依托基础高分子技术，
              研发医用介入导管，目前国内外唯一能够掌握FEP、
              PU材质留置针导管设备技术，
              又能以世界最高精度与性价比量产的创新型高端医疗器械产品研发平台公司。
              成立于2017年5月，专注于精密塑胶导管的研发、制造和销售，
              并可按照客户的要求研发定制各种材质、规格的塑胶导管，
              为客户提供“全程一站式”解决方案。至今，已为上千家国内外客户提供了满意服务，
              是医疗导管设计、制造行业的佼佼者。
              主要产品是各种FEP、TPU,PVC,PP,PE,Pebax,POM,TPE，FEP管材，
              目前主推产品是耐1200PSI的PU高压编织管，
              FEP鞘管、FEP留置针导管、TPU中心静脉导管、TPU血液透析导管，
              各类导管等产品各部件。有多名由高分子材料、医学工程、自动化、挤出成型、
              设备研制等学科专业的研发团队，销售，管理团队。
              有自制的各型号非标准化的挤出设备10台。
            </div>

            <div class="title">项目优势</div>
            <div class="desc">
              医用氟塑料成型技术是很多高值介入耗材的基础，但是由于生产技术难度大、门槛高，目前市场被外资品牌所垄断。
              ?
              奉天医疗掌握的医用氟塑料成型技术，从技术知识、工艺流程到生产设备均为自主突破掌握，
              是国内首个质量上足以与外资品牌抗衡的企业。
              奉天在提供最高级别操作体验的同时价格更低，而且作为国产器械，
              在招标、医保等环节具有天然优势，市场竞争力强。 1.市场现状
              市场巨大，年千亿规模，被国外巨头垄断； 2.发展前景
              每年复合增速18%且高值精密医疗管材应用领域不断增加，有良好的市场发展前景；
              3.竞争对手 芬兰Optinova占全球市场60%，国内无竞争对手。
            </div>

            <div class="title">项目进展</div>
            <div class="desc">
              目前，公司已有洁净厂房2000平方米，国际标准生物、化学、物理与微生物实验室20０平方米，
              各类已立项的医疗器械产品生产设备、用具、检验设备齐全，产品已研发试产成功。公司已通过ISO
              13485医疗器械质量管理体系认证及获得14款产品的欧盟ＣＥ认证。
              公司研发的首个替代进口产品（用于注射造影剂，耐压1200psi）的高压编织延长管的质量在打开国内市场后，
              赫赫有名，于2017年6月中旬成为北京乐普医疗公司采购高压编织管的独家特供供应商，
              2018年5月中旬与威高公司签订心血管介入产品的长期战略合作协议。
              公司采用医用级无毒PVC、PP、PE、PA、TPU、TPE、PEBAX、
              FEP等原料可生产：单腔管、多腔管、双色共挤管、薄壁管、增强型管、微型管、多排管、异型管、X管显影管等，
              能满足不同材料、不同规格、不同颜色、不同硬度及不同功能填料的需求。
              2017年带来2个革命性的突破：第一：采用增强尼龙及多层复合制造技术，
              研制出替代进口，填补空白、全国第一的高压编织管（耐压1200psi）；
              第二：氟塑料在医疗导管领域的突破，FEP由于材料高腐蚀性、高熔点（300摄氏度以上）的特点，
              在医疗导管领域的应用中，是成型极为困难的材料之一，目前心脑血管介入耗材领域，
              它的应用基本集中在导管鞘鞘管、留置针套管两种产品，目前国内厂家全部从国外采购。
              现已实现FEP鞘管的稳定、可控、批量生产。2018年又迎来第3个革命性突破：
              基于FEP鞘管的突破技术，进一步研制FEP留置套管（外径26G-14G即0.44mm-1.7mm），
              精度可达±0.02mm的三色线显影导管，成为国内唯一一家掌握核心技术的生产留置针套管，
              替代进口的企业。
              奉天医疗除了专注于研发医用导管外，还自主研发、生产医疗器械产品成品（32个产品，
              目前有14个产品已获得欧盟CE认证）。主要应用于医院的麻醉科、ＩＣＵ、肾内科、介入科、泌尿外科等科室。
            </div>

            <div class="title">企业介绍</div>
            <div class="desc">
              <el-descriptions
                :column="1"
                :colon="false"
                labelClassName="label"
                contentClassName="content"
                size="mini"
              >
                <el-descriptions-item label="企业"
                  >深圳*****公司</el-descriptions-item
                >
                <el-descriptions-item label="创立时间"
                  >2017-05-10</el-descriptions-item
                >
                <el-descriptions-item label="企业规模"
                  >50-100人</el-descriptions-item
                >
                <el-descriptions-item label="公司说明">
                  深圳奉天医疗技术有限公司是中国首家依托基础高分子技术，研发医用介入导管，
                  目前国内外唯一能够掌握FEP、PU材质留置针导管设备技术，
                  又能以世界最高精度与性价比量产的创新型高端医疗器械产品研发平台公司。
                </el-descriptions-item>
              </el-descriptions>
            </div>

            <div class="title">主要成员</div>
            <div class="desc">暂无</div>

            <div class="title">融资计划</div>
            <div class="desc">
              <div class="item">
                <span>已融轮次：</span>
                <span>尚未获投</span>
              </div>
              <div class="item">
                <span>意向资金：</span>
                <span>无</span>
              </div>
              <div class="item">
                <span>融资需求：</span>
                <span>不需要融资</span>
              </div>
              <div class="item">
                <span>融资方式：</span>
                <span>股权融资</span>
              </div>
            </div>

            <div class="title">推荐创新成果</div>

            <div class="desc">
              <div class="result-wrap">
                <div class="result-box" v-for="i in 4" :key="i">
                  <div class="img">
                    <img
                      src="http://dummyimage.com/100x100/FD6F96"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="line"></div>

                  <div class="item-title item-row">
                    石墨烯/淀粉基生物全降解塑料 全降解无纺布项目
                  </div>

                  <div class="item-row">
                    <span>企业名称：</span>
                    <span>嘉兴*****公司</span>
                  </div>

                  <div class="item-row">
                    <span>所在地区：</span>
                    <span>浙江省 宁波</span>
                  </div>

                  <div class="item-row">
                    <span>行业领域：</span>
                    <span class="tag">航空航天</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">项目人名片</div>
          <div class="avatar">
            <img src="http://dummyimage.com/100x100/3DB2FF" alt="" srcset="" />
          </div>
          <div class="name">蒋秀兰</div>
          <div class="info-row">
            <span>企业名称：</span>
            <span>深圳*****公司</span>
          </div>
          <div class="info-row">
            <span>所属行业：</span>
            <span>医疗器械</span>
          </div>
          <div class="info-row">
            <span>成立时间：</span>
            <span>2017-05-10</span>
          </div>
          <div class="info-row">
            <span>企业规模：</span>
            <span>50-100人</span>
          </div>
          <div class="info-row">
            <span>融资金额：</span>
            <span>无</span>
          </div>
          <div class="info-row">
            <span>联系方式：</span>
            <span>135********</span>
            <span class="btn">查看</span>
          </div>
          <div class="info-row">
            <span>企业名称：</span>
            <span>******</span>
            <span class="btn">查看</span>
          </div>
        </div>
      </div>
    </div>

    <page-foot />
  </div>
</template>

<script>
import PageFoot from "../../../components/ori-com/PageFoot.vue";
export default {
  components: { PageFoot },
};
</script>

<style lang="scss" scoped>
.enterprise-detail {
  width: 100%;
  overflow: hidden;
  background-color: #f9f9f9;

  .container {
    width: 100%;
    overflow: hidden;
  }

  .enterprise-wrap {
    padding-bottom: 30px;
    .top-panel {
      background-color: #fff;
      margin-top: 27px;
      padding: 18px 35px;
      position: relative;

      display: flex;
      align-items: flex-start;

      .img {
        flex: 0 0 auto;
        width: 120px;
        height: 120px;
        overflow: hidden;
        margin-right: 26px;
      }

      .right-content {
        padding-top: 10px;
        flex: 1 1 auto;
        .title-share {
          font-size: 16px;
          font-weight: 700;
          color: #333333;
          line-height: 24px;
          margin: 0;
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          i {
            padding-right: 15px;
            font-size: 16px;
            color: #999;
          }
        }

        .info-wrap {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          & > div {
            flex: 0 0 auto;
          }

          .left {
            width: 60%;
          }

          .center {
            width: 25%;
          }

          .right {
            width: 15%;
            text-align: right;
          }

          .info-row {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 8px;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            line-height: 16px;
            span:nth-child(1) {
              color: #999;
            }
            span.tag {
              margin-right: 10px;
              display: inline-block;
              height: 26px;
              line-height: 26px;
              padding: 0 14px;
              background: #f4f5f9;
              border-radius: 3px;
            }
          }
        }
      }
    }

    .bottom-panel {
      margin-top: 20px;
      display: flex;
      align-items: flex-start;

      .left {
        flex: 1 1 auto;

        .nav-list {
          background-color: #fff;
          span {
            display: inline-block;
            cursor: pointer;
            line-height: 50px;
            padding: 0 30px;
            font-size: 14px;
            font-weight: 400;
            color: #666666;

            &:hover,
            &:active,
            &.active {
              background: #e62d31;
              color: #fff;
            }
          }
        }

        .desc-wrap {
          margin-top: 15px;
          background: #fff;
          padding: 20px 30px;

          .title {
            border-left: 2px solid #e62d31;
            font-size: 14px;
            padding-left: 8px;
            font-weight: 700;
            color: #333333;
            line-height: 14px;
            position: relative;
            left: -10px;
            margin-bottom: 20px;
          }

          .desc {
            margin-bottom: 30px;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            line-height: 24px;

            ::v-deep .label {
              display: inline-block;
              color: #999;
              width: 60px;
              text-align: justify;
              text-align-last: justify;
            }

            ::v-deep .content {
              color: #666;
              max-width: 700px;
            }

            .item {
              display: inline-block;
              margin-right: 20px;
              span:nth-child(1) {
                color: #999;
              }
            }

            .result-wrap {
              display: flex;
              align-items: flex-start;
              .result-box {
                cursor: pointer;
                width: 180px;
                height: 321px;
                background: #ffffff;
                border: 1px solid #e0e0ec;
                margin-right: 13px;
                transition: all 0.5s;

                &:hover,
                &:active {
                  transform: translateY(-5px);
                }

                .img {
                  width: 100px;
                  height: 100px;
                  margin: 0 auto;
                  margin-top: 45px;
                  overflow: hidden;
                }
                .line {
                  width: 165px;
                  margin: 17px auto;
                  height: 1px;
                  border-bottom: 1px dashed #e0e0ec;
                }

                .item-row.item-title {
                  padding: 0 15px;
                  text-align: center;
                  font-size: 14px;
                  font-weight: 700;
                  color: #212c46;
                  margin-bottom: 18px;
                }

                .item-row {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  line-height: 16px;
                  margin-bottom: 8px;
                  padding-left: 20px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #666666;

                  span:nth-child(1) {
                    color: #999;
                  }

                  .tag {
                    display: inline-block;
                    padding: 0 18px 0 12px;
                    height: 26px;
                    line-height: 26px;
                    background: #f4f5f9;
                    border-radius: 3px;
                  }
                }
              }
            }
          }
        }
      }

      .right {
        background-color: #fff;
        flex: 0 0 auto;
        margin-left: 15px;
        width: 330px;
        padding-bottom: 15px;
        .title {
          font-size: 14px;
          font-weight: 700;
          color: #333333;
          line-height: 24px;
          margin-top: 14px;
          padding-left: 14px;
        }

        .avatar {
          margin: 0 auto;
          margin-top: 30px;
          text-align: center;
          img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
          }
        }

        .name {
          color: #333;
          margin: 15px 0 18px 0;
          text-align: center;
          font-size: 12px;
          font-weight: bold;
        }

        .info-row {
          padding: 0 55px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 16px;
          margin-bottom: 8px;
          span:nth-child(1) {
            color: #999;
          }

          span.btn {
            float: right;
            color: #f12a26;
          }
        }
      }
    }
  }
}
</style>